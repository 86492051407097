import request from '@/lib/request';

export const HINTS = Object.freeze({
  CustomProductName: 'custom-product-name',
  CreateRouteForResponse: 'create-route-for-response',
  WelcomePartnersImport: 'welcome-partners-import',
  PopulateCompanyInfo: 'populate-company-info',
  ProductTableInfo: 'product-table-info',
  SubscriptionSwitchedOff: 'subscription-switched-off',
  NewFeatures: 'new-features'
});

class HintService {
  #baseUrl = '/api/hint';

  /**
   Fetch hints for user by key
   @param {string[]} keys
   @returns {Promise<Array>}
   */
  async getUserHints(keys = []) {
    return request.post(`${this.#baseUrl}/user-hints`, { keys });
  }

  /**
   Fetch hints for user by key
   @param {object} params
   @param {string} [params.hintId]
   @param {string} [params.hintName]
   @returns {Promise<{ viewed: boolean }>}
   */
  async view({ hintId, hintName }) {
    return request.patch(`${this.#baseUrl}/view`, { hintId, hintName });
  }
}

export const hintService = new HintService();
