export class ProductDto {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.originalName = props.originalName;
    this.unit = props.unit;
    this.count = +props.count;
    this.price = +props.price;
    this.tax = props.tax;
  }
}
