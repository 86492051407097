.menu:global(.MuiPaper-root) {
  margin-top: var(--indent-xxs);
  border-radius: var(--indent-xxs);
  box-shadow: 0 3px 7px rgba(36 48 103 / 20%);
  background-color: var(--color-white);
  z-index: 0 !important;
}

.subMenu {
  margin: 0 var(--indent-xs) 0 -8px;
  border-radius: var(--indent-xxs);
  box-shadow: 0 3px 7px rgba(36 48 103 / 20%) !important;
  background-color: var(--color-white);
}

.list {
  padding: var(--indent-xs) !important;
  display: flex;
  flex-direction: column;
  gap: var(--indent-xxs);
}

.item:global(.MuiMenuItem-root) {
  padding: var(--indent-xs);
  border-radius: var(--indent-xxs);
  width: 100%;
}

.item:hover:global(.MuiMenuItem-root),
.active {
  background-color: var(--color-blue-50);
}

.delimiter {
  width: 100%;
  border-top: 1px solid var(--color-gray-400);
}

.container {
  display: flex;
  flex-direction: column;
}

.groupItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: var(--indent-xxs);
}

.groupItem:hover {
  background-color: var(--color-blue-50);
  border-radius: var(--indent-xxs);
}

.disabled :global(.MuiMenuItem-disabled) {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.subMenuItem {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subMenuRoot {
  margin: 0 var(--indent-xs) !important;
  z-index: 1500 !important;
}

.active {
  background-color: var(--color-blue-50) !important;
}

.drawerContainer {
  background: var(--color-white);
  overflow: hidden;
  height: auto;
}

.drawerContent {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: var(--indent-m);

  .drawerHeader {
    display: flex;
    justify-content: space-between;
  }
}

.drawer {
  border-top-left-radius: var(--indent-xs);
  border-top-right-radius: var(--indent-xs);
  padding: var(--indent-s) var(--indent-m);
  box-shadow: 0 8px 24px rgb(30 34 46 / 12%), 0 4px 8px rgb(30 34 46 / 4%);
}

.swipeableArea {
  height: 8px;
  display: flex;
  justify-content: center;
  padding: var(--indent-ml) 0;
}

.puller {
  width: 60px;
  height: 7px;
  border-radius: 15px;
  background-color: #d9d9d9;
}
