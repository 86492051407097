.timePicker {
  color: var(--color-gray-900);
  background-color: var(--color-white);

  :global(.MuiTextField-root) {
    width: 100%;

    :global(.MuiInputBase-root) {
      color: inherit;
      font: inherit;
      padding: 0;

      input {
        color: inherit;
        font: inherit;
        padding: var(--indent-xs) var(--indent-m);
        border-right: 1px solid var(--color-gray-600);
        height: 18px;

        &::placeholder {
          color: var(--color-gray-600);
          opacity: 1;
        }
      }

      :global(.MuiOutlinedInput-notchedOutline) {
        border-color: var(--color-gray-500);
      }

      &:hover {
        :global(.MuiOutlinedInput-notchedOutline) {
          border-color: var(--color-blue-200);
        }
      }

      &:global(.MuiOutlinedInput-root.Mui-focused) {
        & > fieldset {
          border: 1px solid var(--color-blue-400);
          border-radius: var(--indent-xxs);
          box-shadow: 0 0 5px rgb(35 105 241 / 31%);
        }
      }

      &:global(.MuiOutlinedInput-root.Mui-disabled) {
        background-color: var(--color-gray-300);

        & > fieldset {
          border: 1px solid transparent;
          border-radius: var(--indent-xxs);
          color: var(--color-gray-700);
        }

        & > input {
          -webkit-text-fill-color: var(--color-gray-700);
          border-right: none;
        }

        & > input::placeholder {
          color: var(--color-gray-600);
        }
      }

      &:global(.MuiOutlinedInput-root.Mui-error) {
        & > fieldset {
          border: 1px solid var(--color-red-error);
          border-radius: var(--indent-xxs);
        }
      }
    }
  }
}

.alarm {
  border: 1px solid transparent;
  cursor: pointer;
  margin-right: var(--indent-xs);
}

.popper {
  background: var(--color-white);
  margin-top: var(--indent-xxs);
  box-shadow: 0 3px 7px 0 rgba(36 48 103 / 20%);
  padding: var(--indent-xs) 0;
  border-radius: var(--indent-xxs);

  .list {
    width: 100%;
    max-height: 254px;
    overflow-y: auto;

    .timeStamp {
      margin: 0 var(--indent-xs);
      padding: var(--indent-xs);
      cursor: pointer;
    }

    .timeStamp:hover {
      background-color: var(--color-blue-50);
    }

    &::-webkit-scrollbar {
      width: 8px;
      background-color: var(--color-gray-200);
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(12 35 98 / 20%);
      border-radius: 13px;
    }
  }
}

.popperIndex {
  z-index: 1301;
}
