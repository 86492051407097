import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handle } from 'core/data/messageBus/frontend';
import * as videoRoomMSG from 'unicat-api/src/services/videoRoom/message/message';
import * as videoRoomPeerMSG from 'unicat-api/src/services/videoRoom/peer/message';
import { videoCallQRY } from 'unicat-api/src/services/videoRoom/message/message';
import { useVideoChatContext } from './useVideoChatContext';
import {
  createdRoomReaction,
  createVideoRoomCMD,
  incomingJoinRequestReaction,
  joinedToRoomReaction,
  joinInCMD,
  leftRoomReaction,
  rejectedJoinReaction,
  sendJoinRequest,
  videoIconClickedEVT,
  videoRoomWasCreatedEvt
} from '../commandsAndEvents';
import { createVideoRoom, onRejectedJoin } from '../handlers';
import * as storeGetters from '../../../storeGetters';
import { useConfirmForJoined } from './useConfirmForJoined';
import { emitToServer } from '../../../storage/message';
import { isFunction } from '../../../utils/utils';
import { useClickByVideoChatIcon } from './useClickByVideoChatIcon';

export function useUnsubscriptionList() {
  const listRef = React.useRef([]);

  return {
    add: (newUnsubFn) => listRef.current.push(newUnsubFn),
    unsubscribeAll: () => {
      listRef.current.forEach(
        (unsubscribeFn) => isFunction(unsubscribeFn) && unsubscribeFn()
      );
      listRef.current = [];
    }
  };
}

export function useVideoChatHandlers() {
  const dispatch = useDispatch();
  const currentEmployeeId = useSelector(storeGetters.getCurrentEmployeeId);
  const context = useVideoChatContext();
  const onReceivedIncomingRequest = useConfirmForJoined();
  const unsubList = useUnsubscriptionList();
  const onClickByVideoChatIcon = useClickByVideoChatIcon();

  // NOTE usefully for debug
  window.getVideoChatCxt = () => context;

  React.useEffect(() => {
    function unsubscribe() {
      emitToServer(videoCallQRY.syncOff);
    }

    emitToServer(videoCallQRY.sync);

    window.addEventListener('beforeunload', unsubscribe);
    return () => {
      unsubscribe();
      window.removeEventListener('beforeunload', unsubscribe);
    };
  }, []);

  React.useEffect(() => {
    unsubList.add(
      handle(videoRoomMSG.videoCallQRY.set, ({ roomId }) => {
        context.joinInChat({ roomId });
      })
    );

    // FIXME Возможно нужно возвращать currentEmployeeId с бека
    unsubList.add(
      handle(videoRoomPeerMSG.videoRoomPeerQRY.set, ({ roomId, members }) => {
        context.startCall({ currentEmployeeId, roomId, members });
      })
    );

    unsubList.add(
      videoIconClickedEVT.handle(({ chatId }) => onClickByVideoChatIcon(chatId))
    );
    unsubList.add(createVideoRoomCMD.handle(createVideoRoom));
    unsubList.add(
      videoRoomWasCreatedEvt.handle((roomId) =>
        joinInCMD.emit(roomId, context.myPeerId)
      )
    );
    unsubList.add(joinInCMD.handle(sendJoinRequest));
    unsubList.add(createdRoomReaction(context.addRoomChatConnection));
    unsubList.add(
      rejectedJoinReaction(({ owner }) => dispatch(onRejectedJoin(owner)))
    );
    unsubList.add(
      joinedToRoomReaction(({ member }) => context.addAdmin(member))
    );
    unsubList.add(incomingJoinRequestReaction(onReceivedIncomingRequest));
    unsubList.add(leftRoomReaction(context.onLeftMember));

    return () => {
      unsubList.unsubscribeAll();
    };
  }, [
    currentEmployeeId,
    context.onLeftMember,
    context.mode,
    context.roomId,
    onReceivedIncomingRequest
  ]);
}
