import { ClipboardEventHandler, CSSProperties, ReactNode } from 'react';
import { Align, TypographyProps } from '@/components/Typography';
import { InputProps } from '@/components/MaskedInput/maskedInput.types';

export type ColumnNativeTypes =
  | 'text'
  | 'mask'
  | 'select'
  | 'number'
  | 'checkbox'
  | 'custom';

export interface StringCellDataDef extends Omit<TypographyProps, 'children'> {}

interface StringCellDef {
  field: 'string';
  data: StringCellDataDef;
}

export type CellManagerDef = StringCellDef;

interface TitlePropsDef {
  row: RowDef;
  rowIndex: number;
  disabled?: boolean;
}

interface CommonColumnDef
  extends Pick<TypographyProps, 'variant' | 'noWrap' | 'align'> {
  accessor: string;
  headerName: string;
  headerClassName?: string;
  headerTitle?: string;
  type: ColumnNativeTypes;
  width?: number;
  colSpan?: number;
  sortable?: boolean;
  editable?: EditableSelectType;
  placeholder?: string | ((row: RowDef, rowIndex: number) => string);
  title?: string | ((props: TitlePropsDef) => string);
  titleMaxWidth?: string | number;
  titleAlign?: Align;
  cellClassName?: string | ((row: RowDef, rowIndex: number) => string);
  style?: CSSProperties;
  endAdornment:
    | ReactNode
    | ((row: RowDef, rowIndex: number) => ReactNode)
    | null;

  startAdornment:
    | ReactNode
    | ((row: RowDef, rowIndex: number) => ReactNode)
    | null;
}

export interface TextColumnDef extends CommonColumnDef {
  type: 'text';
}

export interface MaskColumnDef
  extends CommonColumnDef,
    Pick<InputProps, 'mask' | 'scale'> {
  type: 'mask';
  onChange: (value: string, row: RowDef, rowIndex: number) => void;
  onPaste?: (
    row: RowDef,
    rowIndex: number
  ) => ClipboardEventHandler<HTMLDivElement>;
  className?: string | ((row: RowDef, rowIndex: number) => string);
  valid?: boolean | ((row: RowDef, rowIndex: number) => boolean);
  onFocus?: (
    row: RowDef,
    rowIndex: number
  ) => ClipboardEventHandler<HTMLInputElement>;
  completed?: boolean;
}

export interface SelectColumnDef extends CommonColumnDef {
  type: 'select';
}

export interface NumberColumnDef extends CommonColumnDef {
  type: 'number';
}

export interface CheckboxColumnDef
  extends Pick<
    CommonColumnDef,
    | 'accessor'
    | 'width'
    | 'colSpan'
    | 'headerTitle'
    | 'title'
    | 'titleMaxWidth'
    | 'titleAlign'
    | 'cellClassName'
    | 'style'
  > {
  type: 'checkbox';
  selected: boolean | ((row: RowDef, rowIndex: number) => boolean);
  selectedList: number[];
  onSelect: (row: RowDef, rowIndex: number) => void;
  onSelectAll: () => void;
  disabled: boolean | ((row: RowDef, rowIndex: number) => boolean);
}

export interface CustomColumnDef extends CommonColumnDef {
  type: 'custom';
  renderCell: (data: RenderCellDef) => JSX.Element;
}

export interface RenderCellClassesDef {
  cell: string;
}

export interface RenderCellDef {
  item: RowDef;
  value: unknown;
  itemIndex: number;
  classNames: RenderCellClassesDef;
}

export type ColumnDef =
  | TextColumnDef
  | MaskColumnDef
  | SelectColumnDef
  | NumberColumnDef
  | CheckboxColumnDef
  | CustomColumnDef;

export interface FooterColumnDef {
  value: string | number;
  colSpan?: number;
  className?: string;
}

export interface FooterDef {
  columns: FooterColumnDef[];
}

export type RowDef = Record<string, unknown>;

export interface BaseCellDef {
  width?: number;
}

export interface StringDef {
  data: unknown;
  width?: number;
  colSpan?: number;
  editable?: boolean;
}

export enum SortDirections {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ColumnsMapByAccessorType = Map<string, ColumnDef>;
type DisableSelectType = boolean | ((row: RowDef, rowIndex: number) => boolean);
export type EditableSelectType =
  | boolean
  | ((row: RowDef, rowIndex: number) => boolean);
export type SortType = Record<string, SortDirections>;

export interface EditableTableDef {
  columns: ColumnDef[];
  rows: RowDef[];
  footer?: FooterDef[];
  selectable?: boolean;
  removable?: boolean;
  disableSelect?: DisableSelectType;
  onSelect?: (rowIndexes: number[], rows: RowDef[]) => void;
  onRemove?: (row: RowDef, rowIndex: number) => void;
  onHover?: (row: RowDef, rowIndex: number) => void;
  onSort?: (sort: SortType) => void;
}

export interface TableContextDef {
  accessorsList: string[];
  columns: ColumnDef[];
  columnsMapByAccessor: ColumnsMapByAccessorType;
  rows: RowDef[];
  footer?: FooterDef[];
  sort: SortType;
  selectable?: boolean;
  removable?: boolean;
  disableSelect?: DisableSelectType;
  selectedRows: number[];
  toggleSelectedRows: (rowIndex: number) => void;
  toggleSelectedAllRows: () => void;
  toggleSortRows: (accessor: string, direction: SortDirections) => void;
  onRemove?: (row: RowDef, rowIndex: number) => void;
  onHover?: (row: RowDef, rowIndex: number) => void;
}
