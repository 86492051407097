const { BasePermissionsStrategy } = require('./BasePermissions.strategy');

export const RIGHTS = Object.freeze({
  companyRead: 'company:read',
  companyEdit: 'company:edit',
  companyDelete: 'company:delete',
  financeRead: 'finance:read',
  roleEdit: 'role:edit',
  employeesRead: 'employees:read',
  employeesEdit: 'employees:edit',
  employeesDelete: 'employees:delete',
  permissionsEdit: 'permissions:edit',
  requestsRead: 'requests:read',
  requestsEdit: 'requests:edit',
  requestsWithConfirmationCreate: 'requests:withConfirmation:create',
  requestsWithConfirmationCanExecute: 'requests:withConfirmation:canExecute',
  needsRead: 'needs:read',
  needsEdit: 'needs:edit',
  needsAcceptance: 'needs:goodsAcceptance:management',
  responsesWithConfirmationCanExecute: 'responses:withConfirmation:canExecute',
  responsesRead: 'responses:read',
  responsesEdit: 'responses:edit',
  routesRead: 'routes:read',
  routesEdit: 'routes:edit',
  administrationRead: 'administration:read',
  administrationEdit: 'administration:edit',
  companiesRead: 'companies:read',
  partnersRead: 'partners:read',
  partnersEdit: 'partners:edit',
  apiCompanyAccessTokenGenerate: 'api:companyAccessToken:generate',
  accreditationRead: 'accreditation:read',
  accreditationQuestionnaire: 'accreditation:questionnaire',
  accreditationRequest: 'accreditation:request',
  requestsManualProposal: 'requests:manualProposal',
  warehousesManagement: 'company:warehouses:management',
  marketplaceCustomerEdit: 'marketplace:customer:edit',
  marketplaceSupplierRead: 'marketplace:supplier:read',
  marketplaceSupplierEdit: 'marketplace:supplier:edit'
});

export class LinkPermissionsStrategy extends BasePermissionsStrategy {
  #rights = RIGHTS;

  #permissions = [];

  setPermissions(permissions) {
    this.#permissions = permissions;
  }

  hasPermission(permission) {
    return this.#permissions.includes(permission);
  }

  haveCompanyReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.companyRead);
    }

    return this.#permissions.includes(this.#rights.companyRead);
  }

  haveCompanyEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.companyEdit);
    }

    return this.#permissions.includes(this.#rights.companyEdit);
  }

  haveCompanyDeletePermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.companyDelete);
    }

    return this.#permissions.includes(this.#rights.companyDelete);
  }

  haveRoleEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.roleEdit);
    }

    return this.#permissions.includes(this.#rights.roleEdit);
  }

  haveEmployeesReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.employeesRead);
    }

    return this.#permissions.includes(this.#rights.employeesRead);
  }

  haveEmployeesEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.employeesEdit);
    }

    return this.#permissions.includes(this.#rights.employeesEdit);
  }

  haveEmployeesDeletePermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.employeesDelete);
    }

    return this.#permissions.includes(this.#rights.employeesDelete);
  }

  havePermissionsEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.permissionsEdit);
    }

    return this.#permissions.includes(this.#rights.permissionsEdit);
  }

  haveRequestsReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.requestsRead);
    }

    return this.#permissions.includes(this.#rights.requestsRead);
  }

  haveRequestsEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.requestsEdit);
    }

    return this.#permissions.includes(this.#rights.requestsEdit);
  }

  haveRequestsWithConfirmationCreatePermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.requestsWithConfirmationCreate);
    }

    return this.#permissions.includes(
      this.#rights.requestsWithConfirmationCreate
    );
  }

  haveRequestsWithConfirmationCanExecutePermissions(permissions) {
    if (permissions) {
      return permissions.includes(
        this.#rights.requestsWithConfirmationCanExecute
      );
    }

    return this.#permissions.includes(
      this.#rights.requestsWithConfirmationCanExecute
    );
  }

  haveNeedsReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.needsRead);
    }

    return this.#permissions.includes(this.#rights.needsRead);
  }

  haveNeedsEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.needsEdit);
    }

    return this.#permissions.includes(this.#rights.needsEdit);
  }

  hasNeedsAcceptancePermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.needsAcceptance);
    }

    return this.#permissions.includes(this.#rights.needsAcceptance);
  }

  haveResponsesWithConfirmationCanExecutePermissions(permissions) {
    if (permissions) {
      return permissions.includes(
        this.#rights.responsesWithConfirmationCanExecute
      );
    }

    return this.#permissions.includes(
      this.#rights.responsesWithConfirmationCanExecute
    );
  }

  haveResponsesReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.responsesRead);
    }

    return this.#permissions.includes(this.#rights.responsesRead);
  }

  haveResponsesEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.responsesEdit);
    }

    return this.#permissions.includes(this.#rights.responsesEdit);
  }

  haveRoutesReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.routesRead);
    }

    return this.#permissions.includes(this.#rights.routesRead);
  }

  haveRoutesEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.routesEdit);
    }

    return this.#permissions.includes(this.#rights.routesEdit);
  }

  haveAdministrationReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.administrationRead);
    }

    return this.#permissions.includes(this.#rights.administrationRead);
  }

  haveAdministrationEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.administrationEdit);
    }

    return this.#permissions.includes(this.#rights.administrationEdit);
  }

  haveCompaniesReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.companiesRead);
    }

    return this.#permissions.includes(this.#rights.companiesRead);
  }

  havePartnersReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.partnersRead);
    }

    return this.#permissions.includes(this.#rights.partnersRead);
  }

  havePartnersEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.partnersEdit);
    }

    return this.#permissions.includes(this.#rights.partnersEdit);
  }

  haveCompanyAccessTokenGeneratePermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.apiCompanyAccessTokenGenerate);
    }

    return this.#permissions.includes(
      this.#rights.apiCompanyAccessTokenGenerate
    );
  }

  haveFinanceReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.financeRead);
    }

    return this.#permissions.includes(this.#rights.financeRead);
  }

  haveAccreditationReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.accreditationRead);
    }

    return this.#permissions.includes(this.#rights.accreditationRead);
  }

  haveAccreditationQuestionnairePermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.accreditationQuestionnaire);
    }

    return this.#permissions.includes(this.#rights.accreditationQuestionnaire);
  }

  haveAccreditationRequestPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.accreditationRequest);
    }

    return this.#permissions.includes(this.#rights.accreditationRequest);
  }

  hasManualProposalCreationPrivilege(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.requestsManualProposal);
    }

    return this.#permissions.includes(this.#rights.requestsManualProposal);
  }

  hasWarehousesManagementPrivilege(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.warehousesManagement);
    }

    return this.#permissions.includes(this.#rights.warehousesManagement);
  }

  haveMarketplaceCustomerEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.marketplaceCustomerEdit);
    }

    return this.#permissions.includes(this.#rights.marketplaceCustomerEdit);
  }

  haveMarketplaceSupplierReadPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.marketplaceSupplierRead);
    }

    return this.#permissions.includes(this.#rights.marketplaceSupplierRead);
  }

  haveMarketplaceSupplierEditPermissions(permissions) {
    if (permissions) {
      return permissions.includes(this.#rights.marketplaceSupplierEdit);
    }

    return this.#permissions.includes(this.#rights.marketplaceSupplierEdit);
  }
}
