import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useIsomorphicLayoutEffect } from 'react-use';
import { update, append } from 'ramda';
import * as videoRoomPeerMSG from 'unicat-api/src/services/videoRoom/peer/message';
import { useModal } from '@/hooks';

import { emitToServer } from '@/storage/message';
import { getDisplayNameInfo, noop } from '@/utils/utils';
import { showTooltip } from '@/components/ChatWidget/Tooltip/message';
import * as ModalExit from '@/components/Modal/videoChat/ModalExit';
import * as storeGetters from '@/storeGetters';
import { useLobbyModal } from '@/modules/VideoChat/components/modals/LobbyModal/useLobbyModal';
import { Peer } from '../lib/peer';
import { Media } from '../lib/media';
import {
  sendJoinedToRoom,
  sendJoinRequest,
  sendLeaveRoom
} from '../commandsAndEvents';
import { isAudioTrackMuted, isVideoTrackMuted } from '../utils';
import { useTranscript } from './transcript';
import { SidebarModes } from '../components/Sidebar/modes';

export const VIDEO_CHAT_MODES = {
  Open: 'open',
  Close: 'close',
  Hide: 'hide',
  Lobby: 'lobby'
};

export const VIDEO_CHAT_PANEL_MODES = {
  Open: 'open',
  Close: 'close'
};

export function useVideoChat(config = {}) {
  const { onOpen: showModal } = useModal();
  const [mode, setMode] = React.useState(VIDEO_CHAT_MODES.Close);
  const [panelMode, setPanelMode] = React.useState(VIDEO_CHAT_PANEL_MODES.Open);
  const [chatId, setChatId] = React.useState(null);
  const [roomId, setRoomId] = React.useState(null);
  const [roomChatConnectionsMap, setRoomChatConnectionsMap] = React.useState(
    new Map()
  );
  const [sidebarMode, setSidebarMode] = React.useState(SidebarModes.None);
  const [adminsList, setAdminsList] = React.useState(new Set());
  const [canUseCamera, setCanUseCamera] = React.useState(true);
  const [canUseMicrophone, setCanUseMicrophone] = React.useState(true);

  const [showLobbyModal] = useLobbyModal();

  const currentUser = useSelector(storeGetters.getCurrentUser);

  const myPeerRef = React.useRef(null);
  const myMediaRef = React.useRef(null);
  const prevPreferences = React.useRef(null);
  const [videoChats, setVideoChats] = React.useState([]);
  const myPeerId = myPeerRef.current?.id;

  const transcript = useTranscript(
    roomId,
    myPeerRef.current?.currentMember,
    getCurrentMember()?.isMicrophoneMuted
  );

  useIsomorphicLayoutEffect(() => {
    if (!myMediaRef.current) {
      myMediaRef.current = new Media({ config: config.video });
    }

    if (!myPeerRef.current) {
      myPeerRef.current = initializePeer();
    }

    return () => {
      if (myMediaRef.current) {
        myMediaRef.current.endStream();
      }

      if (myPeerRef.current) {
        myPeerRef.current.endCall();
      }
    };
  }, []);

  const showWindow = React.useCallback(() => {
    setMode(VIDEO_CHAT_MODES.Open);
  }, []);

  useIsomorphicLayoutEffect(() => {
    if (!myPeerRef.current) return;

    myPeerRef.current.currentMember = makeCurrentMember(currentUser);
  }, [currentUser, myPeerRef.current?.id]);

  React.useEffect(() => {
    if ([VIDEO_CHAT_MODES.Lobby, VIDEO_CHAT_MODES.Open].includes(mode)) {
      (async () => {
        const cameraPermission = await Media.hasCameraPermission();
        setCanUseCamera(cameraPermission);

        const microphonePermission = await Media.hasMicrophonePermission();
        setCanUseMicrophone(microphonePermission);
      })();
    }
  }, [mode]);

  function initializePeer() {
    return new Peer({
      config: config.peerConfig,
      onMuteVideo,
      onUnmuteVideo,
      currentMember: makeCurrentMember(currentUser)
    });

    function onMuteVideo(peerId) {
      setVideoChats((prevState) =>
        updateListByPeerId(prevState, peerId, { isCameraMuted: true })
      );
    }

    function onUnmuteVideo(peerId) {
      setVideoChats((prevState) =>
        updateListByPeerId(prevState, peerId, { isCameraMuted: false })
      );
    }
  }

  const addRoomChatConnection = React.useCallback(
    ({ roomId: _roomId, chatId: _chatId }) => {
      setRoomChatConnectionsMap((prevState) => prevState.set(_roomId, _chatId));
    },
    []
  );

  const hideWindow = React.useCallback(() => {
    setMode(VIDEO_CHAT_MODES.Hide);
    showTooltip('return-to-video-call');
  }, []);

  const closeWindow = React.useCallback(() => {
    setMode(VIDEO_CHAT_MODES.Close);
  }, []);

  const getMyPeer = React.useCallback(() => myPeerRef.current, [myPeerRef]);

  const joinInChat = React.useCallback(
    async ({ roomId: _roomId }) => {
      if (myPeerRef.current?.myPeer?.disconnected) {
        try {
          await myPeerRef.current.myPeer.reconnect();
        } catch (reason) {
          console.error('Reconnect to peer failure', reason);
          return;
        }
      }

      subscribeMembers({ roomId: _roomId, peerId: myPeerRef.current.id });

      myPeerRef.current.onIncomingCall();
      myPeerRef.current.onStartRemoteStream = (
        remoteVAStream,
        remotePeerId,
        call
      ) => {
        const newMember = call.metadata.initiator;
        const label = newMember
          ? getDisplayNameInfo(newMember)
          : { name: '', initials: '' };
        const avatarSrc = newMember ? newMember.avatarSrc : '';

        setVideoChats((prevState) =>
          addOrUpdateListByPeerId(prevState, remotePeerId, {
            peerId: remotePeerId,
            stream: remoteVAStream,
            isCameraMuted: isVideoTrackMuted(remoteVAStream),
            isMicrophoneMuted: newMember.isMicrophoneMuted,
            label,
            avatarSrc
          })
        );
      };
      // NOTE: Удалить после проверки на тесте отключение/включение камеры
      myPeerRef.current.onUpdateRemoteStream = (
        remoteVAStream,
        id,
        currentRs
      ) => {
        console.info(
          `remote stream with id = ${id} was updated, currentRS is ${currentRs}`
        );
      };
      myPeerRef.current.onUpdateMember = (member) => {
        setVideoChats((prevState) => {
          const prevMember =
            prevState.find(
              (memberInfo) => memberInfo.peerId === member.peerId
            ) ?? {};

          return addOrUpdateListByPeerId(prevState, member.peerId, {
            ...prevMember,
            ...member,
            label: getDisplayNameInfo(member)
          });
        });
      };
      myPeerRef.current.onLeaveMember = (peerId) => {
        setVideoChats((prevState) => removeFromListByPeerId(prevState, peerId));
      };
      myPeerRef.current.onCloseCall = (peerId) => {
        unsubscribeMembers({ roomId: _roomId, peerId });
        sendLeaveRoom(_roomId);
        myMediaRef.current.endStream();
      };
      myMediaRef.current.onUpdateTrack = myPeerRef.current.replaceVATrack;
      setPanelMode(VIDEO_CHAT_PANEL_MODES.Open);
    },
    [videoChats, myMediaRef]
  );

  const initCurrentStream = React.useCallback(async () => {
    const peerId = myPeerRef.current.id;

    const video = prevPreferences.current
      ? !prevPreferences.current.isCameraMuted
      : canUseCamera;
    const audio = prevPreferences.current
      ? !prevPreferences.current.isMicrophoneMuted
      : canUseMicrophone;

    const stream = await myMediaRef.current.startStream({ video, audio });
    const currentMember = makeCurrentMember(currentUser);

    const isCameraMuted = isVideoTrackMuted(stream);
    const isMicrophoneMuted = isAudioTrackMuted(stream);

    setVideoChats((prevState) =>
      addOrUpdateListByPeerId(prevState, peerId, {
        type: 'my-stream',
        peerId,
        stream,
        employeeId: currentMember.employeeId,
        isCameraMuted,
        isMicrophoneMuted,
        label: getDisplayNameInfo(currentMember, true),
        avatarSrc: currentMember.avatarSrc
      })
    );

    myPeerRef.current.updateCurrentMember({
      ...currentMember,
      isCameraMuted,
      isMicrophoneMuted
    });
    myPeerRef.current.setMyVAStream(stream);
  }, [currentUser, canUseCamera, canUseMicrophone]);

  const destroyCurrentStream = React.useCallback(() => {
    if (myMediaRef.current) {
      myMediaRef.current.endStream();
    }

    setVideoChats([]);
  }, []);

  const startCall = React.useCallback(
    async ({ currentEmployeeId, roomId: _roomId, members: membersIds }) => {
      if (myPeerRef.current?.myPeer?.disconnected) {
        try {
          await myPeerRef.current.myPeer.reconnect();
        } catch (reason) {
          console.error('Reconnect to peer failure', reason);
          return;
        }
      }

      const peerId = myPeerRef.current.id;
      setRoomId(_roomId);
      setChatId(roomChatConnectionsMap.get(_roomId));
      showWindow();

      // TODO проверить повторный звонок
      if (!myMediaRef.current.isStreamActive) {
        await initCurrentStream();
      }

      myPeerRef.current.callMany(membersIds);

      sendJoinedToRoom({
        roomId: _roomId,
        employeeId: currentEmployeeId,
        peerId
      });
    },
    [videoChats, roomChatConnectionsMap, initCurrentStream]
  );

  const endVideoCall = useCallback(() => {
    closeWindow();
    prevPreferences.current = null;
    setChatId(null);
    setRoomId(null);
  }, [closeWindow]);

  const reconnectToCall = useCallback(() => {
    addRoomChatConnection({ roomId, chatId });

    const peerId = myPeerRef.current.id;
    sendJoinRequest(roomId, peerId);

    showWindow();
    setPanelMode(VIDEO_CHAT_PANEL_MODES.Open);
  }, [showWindow, addRoomChatConnection, myPeerId, roomId, chatId]);

  const endCall = React.useCallback(
    ({ onSubmited = noop } = {}) => {
      showModal('SIMPLE_SUBMIT', {
        title: 'videoChat.modals.confirmCloseVideoChat.text',
        textBtnConfirm: 'videoChat.modals.confirmCloseVideoChat.confirmBtn',
        textBtnCancel: 'videoChat.modals.confirmCloseVideoChat.cancelBtn',
        submitAction: () => {
          const currentMember = getCurrentMember();

          prevPreferences.current = {
            isMicrophoneMuted: currentMember.isMicrophoneMuted,
            isCameraMuted: currentMember.isCameraMuted
          };
          myMediaRef.current.endStream();
          myPeerRef.current.endCall();
          setVideoChats([]);
          setRoomChatConnectionsMap(new Map());
          setAdminsList(new Set());
          setPanelMode(VIDEO_CHAT_PANEL_MODES.Close);
          setSidebarMode(SidebarModes.None);
          myPeerRef.current = initializePeer();
        },
        onSubmited: () => {
          onSubmited();
          showModal(ModalExit.key, { endVideoCall });
        },
        alignmentTop: true
      });
    },
    [videoChats]
  );

  const showLobby = useCallback(
    (roomId, chatId) => {
      setMode(VIDEO_CHAT_MODES.Lobby);
      showLobbyModal({
        onSubmit: () => {
          addRoomChatConnection({ roomId, chatId });

          const peerId = myPeerRef.current.id;
          sendJoinRequest(roomId, peerId);
        }
      });
    },
    [addRoomChatConnection, myPeerRef]
  );

  const toggleVideo = React.useCallback(
    (isEnabled) => {
      if (!myMediaRef.current) {
        console.error(`Toggle video failed because ref not found`);
        return;
      }

      myMediaRef.current.toggleVideo(isEnabled);

      setVideoChats((prevState) =>
        updateListByPeerId(prevState, myPeerRef.current.id, {
          isCameraMuted: !isEnabled
        })
      );
    },
    [myPeerRef, myMediaRef]
  );

  const toggleAudio = React.useCallback(
    (isEnabled) => {
      if (!myMediaRef.current) {
        console.error(`Toggle audio failed because ref not found`);
        return;
      }

      myMediaRef.current.toggleAudio(isEnabled);
      myPeerRef.current.toggleMyAudio(!isEnabled);

      setVideoChats((prevState) =>
        updateListByPeerId(prevState, myPeerRef.current.id, {
          isMicrophoneMuted: !isEnabled
        })
      );
    },
    [myPeerRef, myMediaRef]
  );

  const onLeftMember = React.useCallback(
    ({ member }) => {
      setVideoChats((prevState) =>
        removeFromListByPeerId(prevState, member.peerId)
      );
    },
    [videoChats]
  );

  const closeSidebar = React.useCallback(() => {
    setSidebarMode(SidebarModes.None);
  }, []);

  const changeSidebarMode = React.useCallback(
    (nextMode) => {
      if (nextMode === sidebarMode) {
        closeSidebar();
        return;
      }

      setSidebarMode(nextMode);
    },
    [sidebarMode]
  );

  const addAdmin = React.useCallback((member) => {
    if (member.isOwner) {
      setAdminsList((prevAdminsList) => prevAdminsList.add(member.employeeId));
    }
  }, []);

  function checkIsCurrentMemberAdmin() {
    return adminsList.has(currentUser.get('employeeId'));
  }

  function getCurrentMember() {
    return videoChats.find(
      (member) => member.employeeId === currentUser.get('employeeId')
    );
  }

  return {
    mode,
    panelMode,
    chatId,
    roomId,
    canUseCamera,
    canUseMicrophone,
    roomChatConnectionsMap,
    addRoomChatConnection,
    showWindow,
    hideWindow,
    videoChats,
    getMyPeer,
    myPeerId,
    showLobby,
    joinInChat,
    initCurrentStream,
    destroyCurrentStream,
    startCall,
    endCall,
    reconnectToCall,
    toggleVideo,
    toggleAudio,
    onLeftMember,
    transcript,
    sidebarMode,
    changeSidebarMode,
    closeSidebar,
    addAdmin,
    currentMember: getCurrentMember(),
    isCurrentMemberAdmin: checkIsCurrentMemberAdmin()
  };
}

function subscribeMembers({ roomId, peerId }) {
  emitToServer(videoRoomPeerMSG.videoRoomPeerQRY.sync, { roomId, peerId });
}

function unsubscribeMembers({ roomId, peerId }) {
  emitToServer(videoRoomPeerMSG.videoRoomPeerQRY.syncOff, { roomId, peerId });
}

function makeCurrentMember(memberData) {
  return {
    employeeId: memberData.get('employeeId'),
    firstName: memberData.get('firstName'),
    lastName: memberData.get('lastName'),
    middleName: memberData.get('middleName'),
    avatarSrc: memberData.get('avatar'),
    label: getDisplayNameInfo(
      {
        firstName: memberData.get('firstName'),
        lastName: memberData.get('lastName')
      },
      true
    )
  };
}

function addOrUpdateListByPeerId(list = [], peerId, newData) {
  const index = list.findIndex((i) => i.peerId === peerId);

  if (index !== -1) {
    return update(index, { ...list[index], ...newData }, list);
  }

  return append(newData, list);
}

function updateListByPeerId(list = [], peerId, newData) {
  const index = list.findIndex((i) => i.peerId === peerId);

  if (index !== -1) {
    return update(index, { ...list[index], ...newData }, list);
  }

  return list;
}

function removeFromListByPeerId(list = [], peerId) {
  return list.filter((i) => i.peerId !== peerId);
}
