import { useCallback } from 'react';
import { Tooltip as MUITooltip } from '@mui/material';
import cn from 'classnames';
import { HintProps } from './Hint.types';
import styles from './Hint.module.scss';
import Info from './assets/information.svg';

function Pointer() {
  return (
    <div className={styles.pointer}>
      <div className={styles.line} />
      <div className={styles.circle} />
    </div>
  );
}

const componentsProps = {
  tooltip: {
    style: {
      background: 'transparent',
      padding: '0',
      margin: '0',
      transform: 'translate(6px)'
    }
  },
  popper: {
    disablePortal: true,
    modifiers: [
      {
        name: 'flip',
        enabled: false,
        options: {
          altBoundary: false,
          rootBoundary: 'viewport',
          padding: 8
        }
      },
      {
        name: 'preventOverflow',
        enabled: false,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'viewport',
          padding: 8
        }
      }
    ]
  }
};

export function Hint({
  children,
  Content,
  open,
  className,
  rootClassName
}: HintProps) {
  const HintContent = useCallback(
    () => (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.info}>
            <Info width={18} height={18} />
          </div>
          {Content}
        </div>
      </div>
    ),
    [Content]
  );

  return (
    <div className={cn(styles.hint, rootClassName)}>
      <MUITooltip
        placement="top"
        title={<HintContent />}
        open={open}
        arrow
        components={{ Arrow: Pointer }}
        componentsProps={componentsProps}>
        <div
          className={cn(
            className,
            styles.children,
            open ? styles.highlight : ''
          )}>
          {children}
        </div>
      </MUITooltip>
    </div>
  );
}
