import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from '@/components/EditableTable/components';
import { isBoolean, isFunction, notEmpty } from '@/lib/utils';
import {
  ColumnsMapByAccessorType,
  EditableTableDef,
  TableContextDef
} from './types';
import { TableContextProvider } from './EditableTable.context';
import { useSort } from './hooks';
import './EditableTable.scss';

export const EditableTable = memo<EditableTableDef>(
  ({
    columns,
    rows,
    footer,
    selectable,
    onSelect,
    disableSelect,
    onHover,
    removable,
    onRemove,
    onSort
  }) => {
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    useEffect(() => {
      if (selectable && isFunction(onSelect)) {
        onSelect(
          selectedRows,
          rows.filter((_, rowIndex) => selectedRows.includes(rowIndex))
        );
      }
    }, [selectedRows]);

    const [sort, toggleSortRows] = useSort(onSort);

    const toggleSelectedRows = useCallback(
      (rowIndex: number) => {
        setSelectedRows((prevState) =>
          prevState.includes(rowIndex)
            ? prevState.filter(
                (selectedRowIndex) => selectedRowIndex !== rowIndex
              )
            : [...prevState, rowIndex]
        );
      },
      [setSelectedRows]
    );

    const availableForSelect = useMemo(() => {
      if (isBoolean(disableSelect) && disableSelect === false) return [];

      if (isFunction(disableSelect)) {
        return rows
          .map((row, rowIndex) =>
            disableSelect(row, rowIndex) ? rowIndex : null
          )
          .filter(notEmpty);
      }

      return rows.map((_, rowIndex) => rowIndex);
    }, [rows, disableSelect]);

    const toggleSelectedAllRows = useCallback(() => {
      setSelectedRows((prevSelected) =>
        !prevSelected.length ? availableForSelect : []
      );
    }, [setSelectedRows, availableForSelect]);

    const accessorsList = useMemo(
      () => columns.map((column) => column.accessor),
      [columns]
    );

    const columnsMapByAccessor = useMemo(
      () =>
        columns.reduce<ColumnsMapByAccessorType>((map, column) => {
          map.set(column.accessor, column);

          return map;
        }, new Map()),
      [columns]
    );

    const context = useMemo<TableContextDef>(
      () => ({
        accessorsList,
        columns,
        columnsMapByAccessor,
        rows,
        footer,
        selectable,
        selectedRows,
        toggleSelectedRows,
        toggleSelectedAllRows,
        disableSelect,
        onHover,
        removable,
        onRemove,
        sort,
        toggleSortRows
      }),
      [
        accessorsList,
        columnsMapByAccessor,
        columns,
        rows,
        footer,
        selectedRows,
        selectable,
        toggleSelectedAllRows,
        toggleSelectedRows,
        disableSelect,
        onHover,
        removable,
        onRemove,
        sort,
        toggleSortRows
      ]
    );

    return (
      <TableContextProvider context={context}>
        <Table />
      </TableContextProvider>
    );
  }
);
