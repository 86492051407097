import { isEmpty } from 'ramda';
import { purchaseRequestService } from '@/api';
import customHistory from '../customHistory';

import { setErrorMessage, setSuccessMessage } from './message';
import { gaSend } from './services';
import request from '../lib/request';

export const setRequest = (request) => ({
  type: 'PURCHASE_REQUEST:GET_REQUEST:SUCCESS',
  payload: { request }
});

export const removeResponded = () => ({
  type: 'PURCHASE_REQUEST:RESPOND:DELETE'
});

export const setResponses = (responses) => ({
  type: 'PURCHASE_REQUEST:RESPONSES',
  payload: { responses }
});

export const setInvoicedResponse = (payload) => ({
  type: 'PURCHASE_REQUEST:SEND_INVOICED:SUCCESS',
  payload
});

export const bindProducts = ({ requestId, responseId, products }) => ({
  type: 'PURCHASE_REQUEST:BIND_PRODUCTS',
  payload: { requestId, responseId, products }
});

export const rejectAllPendingResponses = ({ requestId }) => ({
  type: 'PURCHASE_REQUEST:REJECT_ALL_PENDING_RESPONSES',
  payload: { requestId }
});

export const setAuctionFinishedToAllResponses = ({ requestId }) => ({
  type: 'PURCHASE_REQUEST:SET_AUCTION_FINISHED_TO_ALL_RESPONSES',
  payload: { requestId }
});

export const setAuctionIsFinished = ({ requestId }) => ({
  type: 'PURCHASE_REQUEST:SET_AUCTION_IS_FINISHED',
  payload: { requestId }
});

export const unpublishPurchaseRequest = (payload) => ({
  type: 'PURCHASE_REQUEST:UNPUBLISH:SUCCESS',
  payload
});

export const cancelPurchaseRequest = (payload) => ({
  type: 'PURCHASE_REQUEST:CANCEL:SUCCESS',
  payload
});

export const completePurchaseRequest = (payload) => ({
  type: 'PURCHASE_REQUEST:COMPLETE:SUCCESS',
  payload
});

export const updatePurchaseRequestFeedback = (payload) => ({
  type: 'PURCHASE_REQUEST:FEEDBACK',
  payload
});

export const updatePurchaseRequestStatus = ({
  id,
  status,
  finalizeAt,
  responseEndDate
}) => ({
  type: 'PURCHASE_REQUEST:CHANGE_STATUS',
  payload: { id, status, finalizeAt, responseEndDate }
});

export const changePublishedRequestStatus = ({
  id,
  status,
  finalizeAt,
  responseEndDate
}) => ({
  type: 'PURCHASE_REQUEST:CHANGE_STATUS_OF_PUBLISHED_REQUEST',
  payload: { id, status, finalizeAt, responseEndDate }
});

export const updateExecutorStatus = ({
  executorEmployeeId,
  requestId,
  status
}) => ({
  type: 'PURCHASE_REQUEST:UPDATE_EXECUTOR_STATUS',
  payload: { executorEmployeeId, requestId, status }
});
export const setRequestsSearch = (payload) => ({
  type: 'PURCHASE_REQUEST:SET_REQUESTS_SEARCH',
  payload
});

export const reset = () => ({
  type: 'PURCHASE_REQUEST:RESET'
});

export const setBookmarked = (payload) => ({
  type: 'PURCHASE_REQUEST:SET_IS_BOOKMARKED',
  payload
});

export const updateRequestRespond = (payload) => ({
  type: 'PURCHASE_REQUEST:UPDATE_REQUEST_RESPOND',
  payload
});

export const removeRequestRespond = (payload) => ({
  type: 'PURCHASE_REQUEST:REMOVE_REQUEST_RESPOND',
  payload
});

export const toggleBookmarkRequest = (id) => async (dispatch) => {
  const data = await request.get(`/api/requests/${id}/bookmark/toggle`);
  dispatch(setBookmarked(data));
};

export const updateRequest = (payload) => ({
  type: 'PURCHASE_REQUEST:EDIT:SUCCESS',
  payload
});

export const manipulateRequestFailed = (payload) => ({
  type: 'PURCHASE_REQUEST:EDIT:FAILURE',
  payload: { error: payload.error }
});

export const getAllRequests =
  ({
    limit = 20,
    offset = 0,
    search = '',
    order = '',
    direction = 'DESC',
    selectedCategoriesId = [],
    showMode = [],
    filters = [],
    lang
  }) =>
  (dispatch) => {
    dispatch({
      type: 'PURCHASE_REQUEST:GET_REQUESTS:REQUEST',
      payload: { size: -1 }
    });

    return purchaseRequestService
      .getAllRequests({
        limit,
        offset,
        search: removeZeros(search),
        order,
        direction,
        selectedCategoriesId,
        showMode,
        filters,
        lang
      })
      .then(
        ({
          requests,
          size,
          selectedCategoriesId: sCats,
          orderBy = '',
          filterApplied,
          showHint
        }) => {
          dispatch({
            type: 'PURCHASE_REQUEST:GET_REQUESTS:SUCCESS',
            payload: {
              publishedRequests: requests,
              size,
              limit,
              offset,
              search,
              order,
              direction,
              selectedCategoriesId: sCats,
              orderBy,
              filterApplied,
              showMode,
              showHint
            }
          });
        }
      )
      .catch((res) => {
        dispatch({
          type: 'PURCHASE_REQUEST:GET_REQUESTS:SUCCESS',
          payload: {
            publishedRequests: [],
            size: 0,
            limit,
            offset,
            search,
            order,
            direction,
            showMode
          }
        });
        console.error(res);
      });

    function removeZeros(string) {
      return string
        .split(' ')
        .map((substring) => {
          if (isRequestNumberWithZeros(substring)) {
            return convertToRequestNumber(substring);
          }
          return substring;
        })
        .join(' ');

      function isRequestNumberWithZeros(substring) {
        return substring[0] === '0' && !isNaN(Number(substring));
      }

      function convertToRequestNumber(substring) {
        return Number(substring).toString();
      }
    }
  };

export const regGetRequest = (id, lang) =>
  request.get(
    `/api/quick-response/requests/${id}${lang ? `?lang=${lang}` : ''}`
  );
export const getPublicRequest = (id, lang) => (dispatch) =>
  regGetRequest(id, lang).then((requestData) => {
    dispatch({
      type: 'PURCHASE_REQUEST:GET_REQUEST:SUCCESS',
      payload: { request: requestData }
    });
  });

export const getMyPublishedRequest = (id, lang) => (dispatch) =>
  fetch(`/api/requests/my/${id}/published${lang ? `?lang=${lang}` : ''}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((requestData) => {
      dispatch({
        type: 'PURCHASE_REQUEST:GET_REQUEST:SUCCESS',
        payload: { request: requestData }
      });
      return requestData;
    })
    .catch((res) => {
      dispatch({
        type: 'PURCHASE_REQUEST:GET_REQUEST:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const getMyRequest = (id) => (dispatch) =>
  fetch(`/api/requests/my/${id}`, {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((requestData) => {
      dispatch({
        type: 'PURCHASE_REQUEST:GET_REQUEST:SUCCESS',
        payload: { request: requestData }
      });
      return requestData;
    })
    .catch((res) => {
      dispatch({
        type: 'PURCHASE_REQUEST:GET_REQUEST:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const getRequestResponsibles = () => (dispatch) =>
  fetch('/api/requests/responsibles', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then((responsibles) => {
      dispatch({
        type: 'PURCHASE_REQUEST:GET_RESPONSIBLES:SUCCESS',
        payload: { responsibles }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PURCHASE_REQUEST:GET_RESPONSIBLES:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

function excludeBaseCurrencyFromRequest(request) {
  if (!request.currencies) {
    return request;
  }

  return {
    ...request,
    currencies: request.currencies.filter((currency) => !currency.base)
  };
}

export const publishRequest =
  (id, data = null) =>
  async (dispatch, getState) => {
    const preparedData = excludeBaseCurrencyFromRequest(data);

    const response = await request.post(`/api/requests/${id}/publish`, {
      data: preparedData
    });
    if (response.ok) {
      dispatch({
        type: 'PURCHASE_REQUEST:CHANGE_STATUS',
        payload: { id, status: response.procurementStatus }
      });
      dispatch(
        gaSend({
          category: 'Common',
          action: 'publish_request',
          label: getState().getIn(['user', 'user', 'email'])
        })
      );

      return response;
    }
    dispatch({
      type: 'PURCHASE_REQUEST:PUBLISH_REQUEST:FAILURE',
      payload: { error: response.statusText }
    });
    throw new Error(response.statusText);
  };

function isEndResponseDate(item) {
  return new Date() > new Date(item.responseEndDate);
}

function isShowPublication(requestData) {
  const allowPublicationStatuses = ['draft', 'removed_publication'];

  return (
    requestData &&
    allowPublicationStatuses.includes(requestData.status) &&
    !isEndResponseDate(requestData)
  );
}

const generateEditRequestSuccessMessage = () => (dispatch) => {
  dispatch(setSuccessMessage({ key: 'Draft save successfully' }));
};

export const saveRequest =
  (data = {}, isNoticeOn) =>
  (dispatch) =>
    purchaseRequestService
      .saveDraft(data)
      .then((requestData) => {
        if (isNoticeOn) {
          dispatch(generateEditRequestSuccessMessage(requestData));
        }
        dispatch({
          type: 'PURCHASE_REQUEST:EDIT:SUCCESS',
          payload: { request: requestData }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'PURCHASE_REQUEST:EDIT:FAILURE',
          payload: { error: res.error }
        });
      });

export const unpublishRequest = (id) => (dispatch) =>
  fetch(`/api/requests/${id}/unpublish`, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(() => {
      dispatch(setSuccessMessage({ key: 'Unpublished success' }));
      dispatch({
        type: 'PURCHASE_REQUEST:CHANGE_STATUS',
        payload: { id, status: 'removed_publication' }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PURCHASE_REQUEST:UNPUBLISH_REQUEST:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const attachFile = (requestId, ids, link) => () =>
  fetch('/api/storage/purchaserequest/attach', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ requestId, ids })
  })
    .then(() => customHistory.push(link))
    .catch((res) => console.error(res));

export const deleteRequest = (id) => (dispatch) =>
  fetch(`/api/requests/${id}`, {
    credentials: 'include',
    method: 'DELETE'
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'PURCHASE_REQUEST:DELETE_REQUEST:SUCCESS',
        payload: { id }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PURCHASE_REQUEST:DELETE_REQUEST:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });

export const saveDraftRespond =
  ({
    requestId,
    text = '',
    links = [],
    files = [],
    products = [],
    extraOptions,
    nonPriceCriteria,
    currency,
    shopId,
    dimensions
  }) =>
  (dispatch) =>
    fetch(`/api/requests/${requestId}/respond/status/draft`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        text,
        links,
        files,
        products,
        extraOptions,
        nonPriceCriteria,
        currency,
        shopId,
        dimensions
      })
    })
      .then(async (res) => {
        const responseJson = await res.json();

        if (res.status >= 400) {
          return Promise.reject({ ...responseJson, status: res.status });
        }

        return responseJson;
      })
      .then((resp) => {
        dispatch({ type: 'PURCHASE_REQUEST:RESPOND:SUCCESS', payload: resp });
      })
      .catch((res) => {
        dispatch({
          type: 'PURCHASE_REQUEST:RESPOND:FAILURE',
          payload: { error: res.error }
        });

        dispatch(
          setErrorMessage({
            key: 'Respond failure'
          })
        );

        console.error(res);
      });

export const deleteResponse =
  ({ responseId }) =>
  async (dispatch) => {
    await request.delete(`/api/responses/${responseId}`);
    dispatch({ type: 'PURCHASE_REQUEST:RESPOND:DELETE' });
    return Promise.resolve();
  };

export const sendRespondToRequest =
  ({
    requestId,
    text = '',
    links = [],
    files = [],
    products = [],
    extraOptions,
    nonPriceCriteria,
    showAgain = true,
    currency,
    shopId,
    dimensions
  }) =>
  (dispatch) =>
    fetch(`/api/requests/${requestId}/respond/status/sent`, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        text,
        links,
        files,
        products: products.map((product) => ({
          ...product,
          count: +product.count,
          price: +product.price
        })),
        extraOptions,
        nonPriceCriteria,
        showAgain,
        currency,
        shopId,
        dimensions
      })
    })
      .then(async (res) => {
        const responseJson = await res.json();

        if (res.status >= 400) {
          return Promise.reject({ ...responseJson, status: res.status });
        }

        return responseJson;
      })
      .then((resp) => {
        if (resp.message === 'Auction already ended') {
          dispatch(
            setErrorMessage({
              key: 'Respond auction reject'
            })
          );
        } else if (
          resp.message ===
          "Company wasn't accredited or accreditation is expired/revoked"
        ) {
          dispatch(
            setErrorMessage({
              key: 'Accreditation is failed'
            })
          );
        } else if (resp.message === 'Operation restricted') {
          dispatch(
            setErrorMessage({
              key: 'Respond failure'
            })
          );
        } else {
          dispatch(
            setSuccessMessage({
              key: 'Respond success',
              linkKey: 'my-responses',
              linkUrl: '/requests/my-responses'
            })
          );
          dispatch({ type: 'PURCHASE_REQUEST:RESPOND:SUCCESS', payload: resp });
          return { responsibleUserChatId: resp.responsibleUserChatId };
        }

        return null;
      })
      .catch((res) => {
        if (res.message === 'Auction already ended') {
          dispatch(
            setErrorMessage({
              key: 'Respond auction reject'
            })
          );
          return;
        }

        if (
          res.message ===
          "Company wasn't accredited or accreditation is expired/revoked"
        ) {
          dispatch(
            setErrorMessage({
              key: 'Accreditation is failed'
            })
          );
          return;
        }

        dispatch({
          type: 'PURCHASE_REQUEST:RESPOND:FAILURE',
          payload: { error: res.message }
        });

        dispatch(
          setErrorMessage({
            key: 'Respond failure'
          })
        );
      });

export const saveToNewRequest = (data) => ({
  type: 'PURCHASE_REQUEST:SAVE_TO_NEW_REQUEST',
  payload: data
});
export const addAttachmentsToNewRequest = (data) => ({
  type: 'PURCHASE_REQUEST:ADD_ATTACH_TO_NEW_REQUEST',
  payload: data
});
export const deleteNewRequest = () => ({
  type: 'PURCHASE_REQUEST:DELETE_SAVED_REQUEST'
});
export const cleanRequestState = () => ({
  type: 'PURCHASE_REQUEST:DELETE_REQUEST'
});
export const setArchive = (isArchived) => ({
  type: 'PURCHASE_REQUEST:SET_ARCHIVE',
  payload: { isArchived }
});
export const setArchiveRespond = (isArchived) => ({
  type: 'PURCHASE_REQUEST:SET_ARCHIVE_RESPOND',
  payload: { isArchived }
});
export const cleanCountries = () => ({
  type: 'PURCHASE_REQUEST:PURGE_COUNTRIES'
});
export const cleanRegion = () => ({ type: 'PURCHASE_REQUEST:PURGE_REGION' });
export const cleanCities = () => ({ type: 'PURCHASE_REQUEST:PURGE_CITIES' });
export const cleanRequest = () => ({ type: 'PURCHASE_REQUEST:PURGE_REQUEST' });

export const getCountries =
  (search = '', countryId, lang) =>
  (dispatch) => {
    let baseUrl = `/api/requests/countries`;
    if (search) {
      baseUrl += `?search=${search}`;
    }
    if (countryId) {
      baseUrl += `${search ? '&' : '?'}countryId=${countryId}`;
    }
    if (lang) {
      baseUrl += `${search || countryId ? '&' : '?'}lang=${lang}`;
    }

    return fetch(baseUrl, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then((countries) => {
        dispatch({
          type: 'PURCHASE_REQUEST:GET_COUNTRIES:SUCCESS',
          payload: { countries }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'PURCHASE_REQUEST:GET_COUNTRIES:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getRegionsCount = (countryId, lang) => (dispatch) => {
  let baseUrl = `/api/requests/regions/total?countryId=${countryId}`;
  if (lang) {
    baseUrl += `&lang=${lang}`;
  }

  return request
    .get(baseUrl)
    .then((regionsCount) => {
      dispatch({
        type: 'PURCHASE_REQUEST:GET_REGIONS_COUNT:SUCCESS',
        payload: { regionsCount }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PURCHASE_REQUEST:GET_REGIONS_COUNT:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getRegions =
  (countryId, search = '', regionId, lang) =>
  (dispatch) => {
    let baseUrl = `/api/requests/regions?countryId=${countryId}`;
    if (search) {
      baseUrl += `&search=${search}`;
    }
    if (regionId) {
      baseUrl += `&regionId=${regionId}`;
    }
    if (lang) {
      baseUrl += `&lang=${lang}`;
    }

    return fetch(baseUrl, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then((regions) => {
        dispatch({
          type: 'PURCHASE_REQUEST:GET_REGIONS:SUCCESS',
          payload: { regions }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'PURCHASE_REQUEST:GET_REGIONS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getCities =
  (countryId, regionId, search = '', cityId, lang) =>
  (dispatch) => {
    let baseUrl = `/api/requests/cities?countryId=${countryId}`;

    if (search) {
      baseUrl += `&search=${search}`;
    }
    if (cityId) {
      baseUrl += `&cityId=${cityId}`;
    }
    if (lang) {
      baseUrl += `&lang=${lang}`;
    }

    if (Array.isArray(regionId) && !isEmpty(regionId)) {
      baseUrl += regionId.reduce(
        (str, region) => `${str}&regionId[]=${region.value}`,
        ''
      );
    }

    return fetch(baseUrl, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then((cities) => {
        dispatch({
          type: 'PURCHASE_REQUEST:GET_CITIES:SUCCESS',
          payload: { cities }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'PURCHASE_REQUEST:GET_CITIES:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const clearAllRequestResponsesAC = () => ({
  type: 'PURCHASE_REQUEST:ALL_RESPONSES_CLEAR'
});

export const setResponseStatus = ({ responseId, status }) => ({
  type: 'PURCHASE_REQUEST:SET_RESPONSE_STATUS',
  payload: { responseId, status }
});

export const setResponseError = ({ productId, error }) => ({
  type: 'PURCHASE_REQUEST:SET_RESPONSE_ERROR',
  payload: { productId, error }
});

export const clearResponseError = ({ productId }) => ({
  type: 'PURCHASE_REQUEST:CLEAR_RESPONSE_ERROR',
  payload: { productId }
});
