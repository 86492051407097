import { useEditableTableContext } from '@/components/EditableTable/EditableTable.context';
import { makeKey } from '@/components/EditableTable/utils';
import { Row } from '../Row';

export function TableBody() {
  const context = useEditableTableContext();
  const {
    rows,
    onHover,
    onRemove,
    accessorsList,
    columnsMapByAccessor,
    removable
  } = context;

  return (
    <tbody>
      {/* TODO Сгенерировать статичный key вместо rowIndex */}
      {/* eslint-disable-next-line react/no-array-index-key */}
      {rows.map((row, rowIndex) => (
        <Row
          key={row.id ? (row.id as number | string) : makeKey(rowIndex)}
          rowIndex={rowIndex}
          data={row}
          accessorsList={accessorsList}
          columnsMapByAccessor={columnsMapByAccessor}
          removable={removable}
          onHover={onHover}
          onRemove={onRemove}
        />
      ))}
    </tbody>
  );
}
